import HttpUtility from '../utils/http/HttpUtility';
import { ProductList, ProductCategoriesList } from "../utils/http/ApiUrls";


export default class ProductsEffect {
  static async productList(category_key, pageNo, pageSize, orderBy) {
    if (pageNo === null ) pageNo = 1;
    if (pageSize === null ) pageSize = 10;
    if (orderBy === null ) orderBy = "ASC";
    return await HttpUtility.get(`${ProductList}?page_no=${pageNo}&page_size=${pageSize}&order_by_type=${orderBy}` + (category_key === null ? '' : '&category_key=' + category_key));
  }

  static async productCategoryList(pageNo, pageSize, orderBy) {
    if (pageNo === null ) pageNo = 1;
    if (pageSize === null ) pageSize = 10;
    if (orderBy === null ) orderBy = "ASC";
    return await HttpUtility.get(`${ProductCategoriesList}?page_no=${pageNo}&page_size=${pageSize}&order_by_type=${orderBy}`);
  }
}
