import CustomerAction from "./CustomerAction";

const initState = {
  userInquiry: null
};
export default class CustomerReducer {
  static initialState = initState;

  static reducer(state = CustomerReducer.initialState, action) {
    if (action.error) {
      return state;
    }
    switch (action.type) {
      case CustomerAction.SAVE_USER_INQUIRY:
        console.log("state.userInquiry", state, state.userInquiry);
        return {
          ...state,
          userInquiry: action.payload
          // productDetails: {
          //   ...state.productDetails,
          // },
        };        
      default:
        return state;
    }
  }
}
