import React, { useState, useEffect, useRef } from "react";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import { Sheet, Header, Content, Footer, detents, Portal } from 'react-sheet-slide'
import 'react-sheet-slide/style.css'

import { useDispatch } from "react-redux";  //Requesting and Storing Data
import { useSelector } from "react-redux";  //Reading Data
import ProductsAction from "store/Products/ProductsAction";
import InquiryForm from "components/page/others/ContactUs/InquiryForm";

const allCategoryItem = { "active": "Y", "category_key": null, "category_name": "ALL" };

function ProductListing() {
    const dispatch = useDispatch();
    const history = useHistory();    
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCategoryKey, setSelectedCategoryKey] = useState('*');
    
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [fallbackImg, setFallbackImg] = useState(null);
    const [imageIndex, setImageIndex] = useState([]);
    

    const [showInquiryForm, setShowInquiryForm] = useState(false);
    const ref = useRef();

    const productsStore = useSelector((state) => state.products);
    console.log("productsStore", productsStore);
    const productList = productsStore.productDetails.data;
    let categoryList = productsStore.productCategoryDetails.data;    
    categoryList = [allCategoryItem, ...categoryList];
    
    // const [categoryList, setCategoryList] = useState([AllCategoryItem]);
    // const [productList, setProductList] = useState([]);

    useEffect(() => {
        getAllCategories();
        getAllProducts(null);
    }, []);

    const getAllCategories =async () => {
        setIsLoading(true);
        await dispatch(await ProductsAction.productCategoryList(1, 100, 'ASC'));
        setIsLoading(false);
        // setIsLoading(true);
        // await dispatch(await ProductsEffect.productCategoryList(1, 10, 'ASC'));
        // setIsLoading(false);
        //let response = await HttpUtility.get(`${ProductCategoriesList}?page_no=1&page_size=10&order_by_type=ASC`, {});
        //if (response.status === "success") setCategoryList([AllCategoryItem, ...response.data]);
    }

    const getAllProducts =async (category_key) => {
        setIsLoading(true);        
        if (category_key === '*') category_key = null;
        await dispatch(await ProductsAction.productList(category_key, 1, 100, 'ASC'));
        setSelectedCategoryKey(category_key);
        setIsLoading(false);
        // let response = await HttpUtility.get(`${ProductList}?page_no=1&page_size=10&order_by_type=ASC`, {});
        // if (response.status === "success") setProductList(response.data);
    }

    const productClick = (product, fallbackImg) => {
        console.log("product---", product);
        setImageIndex(0);
        setFallbackImg(fallbackImg);
        setSelectedProduct(product);
    }

    
    const onProductInquiry = (product) => {
        console.log("product-----", product);
        setShowInquiryForm(true);
    }
      

    const productImages = [];
    if (selectedProduct !== null) {
        const variantList = selectedProduct.variantList || [];
        variantList.map((variant, key) => {
            const variantImages = variant.variantImages || [];
            variantImages.map(variantImg => productImages.push(variantImg.fullPath));
        });
    }

    if ((productImages == null || productImages.length === 0) && fallbackImg !== null && fallbackImg !== '')
    {
        productImages.push(fallbackImg);
    }
          
    console.log("Categories:", categoryList);
    console.log("Products:", productList);
    console.log("Products Length:", productList.length);
    console.log("Product Images:", productImages);
    console.log("showInquiryForm:", showInquiryForm);
    

    return (<section className="our-portfolio">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 text-center theratio-align-center">
                        <div className="ot-heading is-dots">
                            <span>[ All Products ]</span>
                            <h2 className="main-heading">Curated Collections</h2>
                        </div>
                        {/* <div className="space-40"></div>
                        <ul className="project_filters">
                            {categoryList.map((item, key) => {
                                const selectedClass = selectedCategoryKey === item.category_key ? 'selected btn-details' : 'btn-details';
                                return <li key={key}>
                                    <a href="javascript:void(0)" onClick={() => getAllProducts(item.category_key)} 
                                        data-filter={item.category_key === null ? "*" : `.${item.category_key}`} className={selectedClass}>
                                        {item.category_name}
                                    </a>
                                </li>
                            })}
                        </ul> */}
                        <div>                            
                            <div className="projects-grid pf_4_cols style-5 img-scale w-auto mx-0">
                                <div className="grid-sizer"></div>
                                {productList.length === 0 ? <>No product listed in this category</> :
                                    productList.map((item, key) => {
                                        return <div key={key} className={`project-item ${item.category_key}`}>
                                            <div className="projects-box">
                                                <div className="projects-thumbnail">
                                                    <a href="javascript:void(0)" onClick={()=>productClick(item, item.thumbnail_bg_img_full_path)}>
                                                        <img src={item.thumbnail_bg_img_full_path} alt="" />
                                                    </a>
                                                    <div className="overlay">
                                                        <h6>{item.product_title}</h6>
                                                        <i className="ot-flaticon-add"></i>
                                                    </div>
                                                </div>
                                                <div className="portfolio-info">
                                                    <div className="portfolio-info-inner">
                                                        <h5><a className="title-link" 
                                                            href="javascript:void(0)" onClick={()=>productClick(item, item.thumbnail_bg_img_full_path)}>
                                                                {item.product_title}</a></h5>
                                                    </div>
                                                    <a className="overlay" href="javascript:void(0)" onClick={()=>productClick(item, item.thumbnail_bg_img_full_path)}></a>
                                                </div>
                                            </div>
                                            <a href="javascript:void(0)" style={{marginTop: "10px", marginBottom: "40px"}} className="octf-btn octf-btn-primary btn-large"
                                            onClick={()=>onProductInquiry(item)}>Buy</a>
                                        </div>
                                    })
                                }
                            </div>                           
                            {/* <div className="btn-block text-center">
                                <a href="/#/products-all" className="btn-loadmore octf-btn">Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            
            {selectedProduct !== null && productImages.length > 0 &&
                <Lightbox
                    mainSrc={productImages[imageIndex]}
                    nextSrc={productImages[(imageIndex + 1) % productImages.length]}
                    prevSrc={productImages[(imageIndex + productImages.length - 1) % productImages.length]}
                    onCloseRequest={() => setSelectedProduct(null)}
                    onMovePrevRequest={() => 
                        setImageIndex((imageIndex + productImages.length - 1) % productImages.length) 
                    }
                    onMoveNextRequest={() =>
                        setImageIndex((imageIndex + 1) % productImages.length)
                    }
                />
            }

            {showInquiryForm &&
            <div className="rss-backdrop" style={{ background: '#f7f8f8', minHeight: '100vh' }}>
                <Portal>
                    <Sheet ref={ref} 
                    open={showInquiryForm}
                    onDismiss={() => setShowInquiryForm(false)}
                    onClose={() => console.log('Component unmounted') }
                    selectedDetent={detents.large}
                    detents={props => [ detents.large(props), detents.medium(props) ]}
                    scrollingExpands={true} 
                    useDarkMode={false}
                    useModal={false}
                    style={{background: "#FF0000"}}
                    >
                        {/* <Header>Inquiry Request</Header> */}
                        <Content>
                            <div className="container">
                                <div className="row">
                                    <div className="align-self-center" style={{marginTop: "100px"}}>
                                        <div className="contact-left">
                                            <h2>Inquiry Request</h2>
                                            <p className="font14">Your email address will not be published. Required fields are marked *</p>
                                            <InquiryForm onClose={() => setShowInquiryForm(false)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Content>
                        <Footer>
                            <button type="button" onClick={() => setShowInquiryForm(false)}>
                            Close
                            </button>
                        </Footer>
                    </Sheet>
                </Portal>
            </div>
        }
        </div>
    </section>
    );
}

export default ProductListing;
