import ProductsAction from "./ProductsAction";

const initState = {
  productDetails: {
    data: []
  },
  productDetailsForCategory: {
    data: []
  },
  productCategoryDetails: {
    data: []
  }
};
export default class ProductsReducer {
  static initialState = initState;

  static reducer(state = ProductsReducer.initialState, action) {
    if (action.error) {
      return state;
    }
    switch (action.type) {
      case ProductsAction.SAVE_PRODUCT_DATA:
        console.log("state.productDetails", state, state.productDetails);
        return {
          ...state,
          productDetails: action.payload
          // productDetails: {
          //   ...state.productDetails,
          // },
        };        
      case ProductsAction.SAVE_PRODUCT_DATA_FOR_CATEGORY:
        console.log("state.productDetailsForCategory", state, state.productDetailsForCategory);
        return {
          ...state,
          productDetailsForCategory: action.payload
        };
      case ProductsAction.SAVE_PRODUCT_CATEGORY_DATA:
        console.log("state.productCategoryDetails", state, state.productCategoryDetails);
        return {
          ...state,
          productCategoryDetails: action.payload
          // productCategoryDetails: {
          //   ...state.productCategoryDetails,
          // },
         };
      default:
        return state;
    }
  }
}
