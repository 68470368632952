import BannerSlider from "components/banner/slider";
import SocialMedia from "components/banner/social-media";
import ProductCarousel from "components/page/product/carousel";
import CtaSection from "components/page/cta-section";
import ProductGrid from "components/page/product/grid";
import OfferedServices from "components/page/services";
import { Helmet } from "react-helmet-async";
import ProductListing from "../product/listing";

const Home = () => (
    <div>
        <Helmet>
            <title>Hintage - A Heritage from Hind</title>
            <meta
            name="description"
            content="Hintage - A Heritage from Hind"
            />
        </Helmet>    
        <div id="content" className="site-content">
            <div className="p-0">
                <div className="grid-lines grid-lines-horizontal z-index-1">
                    <span className="g-line-horizontal line-bottom color-line-secondary"></span>
                </div>
                <div className="grid-lines grid-lines-vertical z-index-1">
                    <span className="g-line-vertical line-left color-line-secondary"></span>
                    <span className="g-line-vertical line-right color-line-secondary"></span>
                </div>
                <BannerSlider />
                {/* <SocialMedia /> */}
            </div>                        
            {/* <CtaSection /> */}
            {/* <ProductGrid /> */}
            <ProductListing />
            {/* <ProductCarousel /> */}
            {/* <OfferedServices headless={true} /> */}
        </div>
    </div>
);

export default Home;