import React, { useState } from "react";

import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";

function PrivacyPolicy() {

  return (
      <div>
            <Helmet>
              <title>Hintage Privacy Policy - A Heritage from Hind</title>
              <meta
                name="description"
                content="Hintage Privacy Policy - A Heritage from Hind"
              />
            </Helmet>
            <div id="content" className="site-content">
                <div className="page-header dtable text-center header-transparent page-header-other">
                    <div className="dcell">
                        <div className="container">
                            <h1 className="page-title">Privacy Policy</h1>
                            <ul id="breadcrumbs" className="breadcrumbs none-style">
                                <li><a href="index.html">Home</a></li>
                                <li className="active">Privacy Policy</li>
                            </ul>    
                        </div>
                    </div>
                </div>
            </div>

            <section className="contact-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 align-self-center text-justify mb-12 mb-lg-0" style={{marginTop: isMobile ? "0px" : "75px"}}>
                            <div>
                                <div className="contact-info"><h3>Privacy Policy</h3></div>
                                <div className="contact-info"><h6>USER INFORMATION AND PRIVACY</h6></div>
                                <p className="font14">
                                    Hintage is committed to safeguarding all the information you share with us. We adhere to strict procedures to protect the confidentiality, security, and integrity of data stored on our systems. Access to your information is granted only to those employees who require it to perform their duties. Any employee found to be in violation of our privacy and/or security policies is subject to disciplinary action, including possible termination and civil and/or criminal prosecution. Hintage's utmost priority is to protect your confidential information and privacy.<br />
                                    This privacy policy outlines how we use your personal information collected through the "www.hintage.in" website. Please read this privacy policy before using the www.hintage.in website or submitting any personal information. This policy may be updated in response to changes in information collection, activities performed, or applicable regulations. We encourage you to review the privacy policy whenever you visit our website to ensure that you understand how any personal information you provide to us will be used.
                                </p>
                                <div className="contact-info"><h6>COLLECTION AND USE OF INFORMATION</h6></div>
                                <p className="font14">
                                Hintage collects, processes, and retains information about you when you visit our website "www.hintage.in". You may choose to provide us with information such as your name, email address, company information, street address, telephone number, or other protected information on "www.hintage.in". Personal Information may include, but is not limited to:<br />
                                Your name,<br />
                                Email addresses,<br />
                                Telephone numbers,<br />
                                Country, City, and State<br />
                                </p>
                                <div className="contact-info"><h6>HOW WE USE YOUR INFORMATION</h6></div>
                                <p className="font14">
                                    The information we collect from you may be used in one of the following ways:<br />
                                    1. To gather details about prospective customers: Your information helps us respond more effectively to your requests and queries, making the application interface user-friendly.<br />
                                    2. To send periodic emails: With your prior approval, we may use the information you share with us to communicate with you through emails, text messages, and calls, to provide our product or service-related information and/or for promotional and marketing purposes.<br />
                                </p>
                                <div className="contact-info"><h6>FAIRNESS AND PURPOSE</h6></div>
                                <p className="font14">
                                    Hintage will collect adequate, relevant, and necessary Personal Information and will process such information fairly and lawfully for the purpose it is collected. The purpose of collection will be specified no later than at the time of data collection or on each occasion of change of purpose.<br />
                                </p>
                                <div className="contact-info"><h6>INFORMATION DISCLOSURE</h6></div>
                                <p className="font14">
                                    Hintage does not share, sell, rent, or trade personal information collected through its website "www.hintage.in" with third parties for their sole promotional purposes or as otherwise outlined in this Privacy Policy. Hintage may share information with third-party service providers contracted to provide services on our behalf for business purposes, such as shipping.<br />
                                </p>
                                <div className="contact-info"><h6>CROSS-BORDER DATA TRANSFERS</h6></div>
                                <p className="font14">
                                    In the course of business, working on company projects, or implementing new processes or systems, an operation may necessitate the transfer of personal information to other entities or third parties located outside Hintage's operating country of business. Permissible data transfer mechanisms are defined by applicable law or regulation, and examples include:<br />
                                    1. A data transfer agreement with the party who will access or obtain the personal information;<br />
                                    2. Notice to and/or approval from a country's local data protection authority; or<br />
                                    3. Notice to and/or consent from the individual whose data is to be transferred.<br />
                                </p>
                                <div className="contact-info"><h6>DATA STORAGE</h6></div>
                                <p className="font14">
                                    Hintage may transfer your information from "www.hintage.in" to other databases and store it on Hintage or its supplier systems. Hintage ensures appropriate security controls when storing data on its systems or those of its suppliers.<br />
                                </p>
                                <div className="contact-info"><h6>COMMITMENT TO DATA SECURITY</h6></div>
                                <p className="font14">
                                    Your personally identifiable information is kept secure. Access to this information is granted only to authorized employees, business partners, clients, vendors, affiliates/subsidiaries, and other third-party providers (who have agreed to keep information secure and confidential).<br /><br />
                                    Hintage ensures that our suppliers employ industry-standard security measures to guarantee the security of information through legally binding terms and conditions. Unauthorized access to such areas is prohibited and may lead to criminal prosecution.<br />
                                </p>
                                <div className="contact-info"><h6>PRIVACY CONTACT INFORMATION</h6></div>
                                <p className="font14">
                                        If you have any questions regarding our Privacy Statement or if you need to update, change or remove information, you can do so by contacting us by email: care@hintage.in or by calling : +91 98929 83643
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
  );
}

export default PrivacyPolicy;
