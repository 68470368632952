import React, { useState } from "react";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";

function CtaSection() {
    const history = useHistory();
    const dispatch = useDispatch();

    return (<section className="cta">
    <div className="container">
        <div className="row">
            <div className="col-lg-8 mb-4 mb-lg-0">
                <h2 className="text-light mb-0">Get Incredible Antiques Right Now!</h2>
                <div className="space-5"></div>
                <p className=" mb-0">At every stage, we could supervise your design.</p>
            </div>
            <div className="col-lg-4 text-left text-lg-right align-self-center">
                <div className="ot-button">
                    <a href="/contact" className="octf-btn octf-btn-light border-hover-light">get in touch</a>
                </div>
            </div>
        </div>
    </div>
    </section>);
}

export default CtaSection;