export const BaseUrl = process.env.REACT_APP_HOST_URL;

// Auth
export const Login = `${BaseUrl}/admin-login`;

// Product
export const ProductList = `${BaseUrl}/common/product`;
export const PreviewProductList = `${BaseUrl}/admin/product/upload/preview`;
export const UploadProductList = `${BaseUrl}/admin/product/upload`;
export const SampleProductFileLink = `https://products.hintage.in/templates/ProductUploadTemplate.xlsx`;

//Category
export const ProductCategoriesList = `${BaseUrl}/common/product/category`;

//User Inquiry
export const UserInquiry = `${BaseUrl}/common/user/inquiry`;
