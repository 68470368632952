import NavigationItem from "components/navigation/navigation-item";
import React, { useEffect, useState } from "react";

import { NAVIGATION_MENU } from "../navigation";

const $ = require('jquery');

function Header() {
    useEffect(() => {
        //Search on Header
        $('.toggle_search').on("click", function()    {
            $(this).toggleClass( "active" );
            $('.h-search-form-field').toggleClass('show');
            if ($(this).find('i').hasClass( "ot-flaticon-search" )) {
                $('.toggle_search > i').removeClass( "ot-flaticon-search" ).addClass("ot-flaticon-close-1");
            }else{
                $('.toggle_search > i').removeClass( "ot-flaticon-close-1" ).addClass("ot-flaticon-search");
            }
            $('.h-search-form-inner > form > input.search-field').focus();
        });
        
        //Right Panel Show / hidde on Header
        $('#panel-btn, .side-panel-close, .panel-overlay').on('click', panel_handler);

        //Mobile Menu
        $('#mmenu-toggle, .mmenu-close, .mmenu-overlay').on('click', mmenu_handler);
        $('.mmenu-wrapper li:has(ul)').prepend('<span className="arrow"><i className="ot-flaticon-next"></i></span>');
        $(".mmenu-wrapper .mobile_mainmenu > li span.arrow").on('click',function() {
            $(this).parent().find("> ul").stop(true, true).slideToggle()
            $(this).toggleClass( "active" ); 
        });
    } , []);
  
    function panel_handler() {
        var element = $('#panel-btn'),
        sidebar = $('#side-panel');
    
        var isActive = !element.hasClass('active');

        element.toggleClass('active', isActive);
        sidebar.toggleClass('side-panel-open', isActive);
        $('body').toggleClass('side-panel-active', isActive);
        return false;
    }

    function mmenu_handler() {
        var element = $('#mmenu-toggle'),
        mmenu   = $('#mmenu-wrapper');
        var isActive = !element.hasClass('active');

        element.toggleClass('active', isActive);
        mmenu.toggleClass('mmenu-open', isActive);
        $('body').toggleClass('mmenu-active', isActive);
        return false;
    }

  return (<>
        <header id="site-header" className="site-header header-transparent">
        <div className="octf-main-header is-fixed">
            <div className="octf-area-wrap">
                <div className="container-fluid octf-mainbar-container">
                    <div className="octf-mainbar">
                        <div className="octf-mainbar-row octf-row">
                            <div className="octf-col logo-col no-padding">
                                <div id="site-logo" className="site-logo">
                                    <a href="/">
                                        <img src="images/logo.png" alt="Hintage" className="" style={{padding: "5px"}} />
                                    </a>
                                </div>
                            </div>
                            <div className="octf-col menu-col no-padding">
                                <nav id="site-navigation" className="main-navigation">
                                    <ul className="menu">
                                        <li><a href="/">Home</a></li>
                                        {NAVIGATION_MENU.map((item) => (
                                            <NavigationItem key={item.id} menuItem={item} mobileMenu={false}/>
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                            <div className="octf-col cta-col text-right no-padding">
                                <div className="octf-btn-cta">

                                {/* <div className="octf-cart octf-cta-header">
                                        <a className="cart-contents" href="cart-page.html" title="View your shopping cart">
                                            <i className="ot-flaticon-shopping-bag"></i>
                                            <span className="count">0</span>
                                        </a>
                                    </div> */}

                                    {/* <div className="octf-cart octf-cta-header">
                                        <a className="cart-contents" href="cart-page.html" title="View your shopping cart">
                                            <i className="ot-flaticon-shopping-bag"></i>
                                            <span className="count">2</span>
                                        </a>
                
                                        <div className="site-header-cart">
                                            <div className="widget woocommerce widget_shopping_cart">
                                                <div className="widget_shopping_cart_content">
                                                    <ul className="woocommerce-mini-cart cart_list product_list_widget ">
                                                        <li className="woocommerce-mini-cart-item mini_cart_item">
                                                            <a className="remove remove_from_cart_button" href="#">×</a>                                         
                                                            <a href="single-product.html"><img src="images/product/product9.jpg" className="" alt="" />Velvet Teal Blue</a>
                                                            <span className="quantity">1 × <span className="amount"><bdi><span>$</span>195</bdi></span></span>
                                                        </li>
                                                        <li className="woocommerce-mini-cart-item mini_cart_item">
                                                            <a className="remove remove_from_cart_button" href="#">×</a>
                                                            <a href="single-product.html"><img src="images/product/product7.jpg" className="" alt="" />Natural Pouffe</a>
                                                            <span className="quantity">1 × <span className="amount"><bdi><span>$</span>145</bdi></span></span>
                                                        </li>
                                                    </ul>

                                                    <p className="woocommerce-mini-cart__total total">
                                                        <strong>Subtotal:</strong> <span className="amount"><bdi><span>$</span>340</bdi></span>
                                                    </p>
                                                    <p className="woocommerce-mini-cart__buttons buttons">
                                                        <a href="cart-page.html" className="button wc-forward">View cart</a>
                                                        <a href="checkout-page.html" className="button checkout wc-forward">Checkout</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>  
                                    </div>

                                    <div className="octf-search octf-cta-header">
                                        <div className="toggle_search octf-cta-icons">
                                            <i className="ot-flaticon-search"></i>
                                        </div>
                                        <div className="h-search-form-field collapse">
                                            <div className="h-search-form-inner">
                                                <form role="search" method="get" className="search-form">
                                                    <input type="search" className="search-field" placeholder="SEARCH..." value="" name="s" 
                                                        onChange={() => {}}/>
                                                    <button type="submit" className="search-submit"><i className="ot-flaticon-search"
                                                        onClick={() => {}}></i></button>
                                                </form>
                                            </div>                                  
                                        </div>
                                    </div>
 */}
                                    <div className="octf-sidepanel octf-cta-header">
                                        <div className="site-overlay panel-overlay"></div>
                                        <div id="panel-btn" className="panel-btn octf-cta-icons">
                                            <i className="ot-flaticon-menu"></i>
                                        </div>
                                    </div>
                                    
                                </div>                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="header_mobile">
            <div className="container-fluid">
                <div className="octf-mainbar-row octf-row">
                    <div className="octf-col">
                        <div className="mlogo_wrapper clearfix">
                            <div className="mobile_logo">
                                <a href="/">
                                    <img src="images/logo.png" alt="Hintage" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="octf-col justify-content-end">
                        <div className="octf-search octf-cta-header">
                            <div className="toggle_search octf-cta-icons">
                                <i className="ot-flaticon-search"></i>
                            </div>
                            <div className="h-search-form-field collapse">
                                <div className="h-search-form-inner">
                                    <form role="search" method="get" className="search-form">
                                        <input type="search" className="search-field" placeholder="SEARCH..." value="" name="s" 
                                            onChange={() => {}}/>
                                        <button type="submit" className="search-submit" onClick={() => {}}><i className="ot-flaticon-search"></i></button>
                                    </form>
                                </div>                                  
                            </div>
                        </div>
                        <div className="octf-menu-mobile octf-cta-header">
                            <div id="mmenu-toggle" className="mmenu-toggle">
                                <button><i className="ot-flaticon-menu"></i></button>
                            </div>
                            <div className="site-overlay mmenu-overlay"></div>
                            <div id="mmenu-wrapper" className="mmenu-wrapper on-right">
                                <div className="mmenu-inner">
                                    <a className="mmenu-close" href="#"><i className="ot-flaticon-right-arrow"></i></a>
                                    <div className="mobile-nav">
                                        <ul id="menu-main-menu" className="mobile_mainmenu none-style">
                                            <li><a href="/">Home</a></li>
                                            {NAVIGATION_MENU.map((item, key) => 
                                                <NavigationItem key={item.id} menuItem={item} mobileMenu={true}
                                                    classNames={(key === 0) ? "current-menu-item current-menu-ancestor" : ""} 
                                                    menuToggleHandler={mmenu_handler} />
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    </>);
}

export default Header;
