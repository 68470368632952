import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SnackbarProvider } from "notistack";

import configStore from "./store";

// react-router components
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
import RightPanel from "components/right-panel";
import { NAVIGATION_MENU, FOOTER_ROUTES } from "components/navigation";

import Home from "components/page/home";
import Page404 from "components/page/page404/page404";

const REACT_APP_GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

// Redux Store
const initialStore = {};
const store = configStore(initialStore);

export default function App() {
    const { pathname } = useLocation();
    const history = useHistory();

    const isLogin = store.getState().auth.isLogin;

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);
    
    const createRoute = (key, route) => (
        <Route exact key={key} render={() => route.component} path={route.href}
            //render={props => isAuthenticated ?  React.createElement(route.component, props) : history.push("/login") }
        />);

    console.log("pathname", pathname);

    const getRoutes = (routeMap) => {
        const routeList = [];
        routeMap.map((route) => {
            if (route.component)  routeList.push (route);
            if (route.children) {
              route.children.map((subRoute) => {
                if (subRoute.component)  routeList.push(subRoute);
                else if (subRoute.children) {
                  subRoute.children.map((subSubRoute) => {
                    if (subSubRoute.component) routeList.push(subSubRoute);
                  });
                }
              });
            }
          }
        );
        return routeList;
    }

    return (<Provider store={store}>
        <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
            <SnackbarProvider maxSnack={3}>
            <HelmetProvider>
                <div className="royal_preloader">
                    <div id="page" className="site">
                        <Header />                    
                        <RightPanel />
                        <Switch>
                            {getRoutes(NAVIGATION_MENU).map((route, key) => createRoute(key, route))}
                            {getRoutes(FOOTER_ROUTES).map((route, key) => createRoute(key, route))}                            
                            <Route exact path="/" component={Home} />
                            <Route component={Page404} />
                        </Switch>
                        <Footer />
                    </div>            
                </div>
            </HelmetProvider>
            </SnackbarProvider>
        </GoogleOAuthProvider>
    </Provider>);
}