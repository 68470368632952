import { Link } from "react-router-dom";

export default function NavigationItem (props) {
    const menuItem = props.menuItem;
    const classNames = props.classNames;
    const mobileMenu = props.mobileMenu;
        if (menuItem.children) {
        return <li className={`menu-item-has-children ${classNames}`}>
                    {mobileMenu && <span className="arrow"><i className="ot-flaticon-next"></i></span>}
                    <a href={menuItem.href}>{menuItem.name}</a>
                    <ul className="sub-menu">
                        {menuItem.children.map((subMenu) => (
                            <NavigationItem key={subMenu.id} menuItem={subMenu} mobileMenu={mobileMenu}/>
                        ))}
                    </ul>
                </li>
    }
    else {
        return <li><Link to={menuItem.href}>{menuItem.name}</Link></li>
    }
}