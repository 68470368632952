import React, { useState } from "react";

import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";

function Return() {

  return (
      <div>
            <Helmet>
              <title>Hintage Return - A Heritage from Hind</title>
              <meta
                name="description"
                content="Hintage Return - A Heritage from Hind"
              />
            </Helmet>
            <div id="content" className="site-content">
                <div className="page-header dtable text-center header-transparent page-header-other">
                    <div className="dcell">
                        <div className="container">
                            <h1 className="page-title">Return</h1>
                            <ul id="breadcrumbs" className="breadcrumbs none-style">
                                <li><a href="index.html">Home</a></li>
                                <li className="active">Return</li>
                            </ul>    
                        </div>
                    </div>
                </div>
            </div>

            <section className="contact-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 align-self-center text-justify mb-12 mb-lg-0" style={{marginTop: isMobile ? "0px" : "75px"}}>
                            <div>
                                <div className="contact-info"><h3>Return Policy</h3></div>
                                <p className="font14">
                                    Hintage endeavors to ensure that every transaction on our website is seamless. We take great care in delivering our products and adhere to the highest quality standards.<br />
                                    As a policy, we do not offer return or exchange on products that are delivered in perfect condition as per the order placed. However, if the product is wrongfully delivered (product doesn't match the item in the order confirmation) or has a genuine quality/manufacturing defect or is damaged during shipping, we are open to extending a return or exchange for your order.<br />
                                    In rare scenarios, if any product gets damaged during transit, we take full accountability for the damage, allowing you to shop without any concerns about shipping damage.<br />
                                </p>
                                <div className="contact-info"><h6>Eligibility for Return & Exchange</h6></div>
                                <p className="font14">
                                    1. Handmade & Handpainted products may have minor imperfections in terms of color shades, texture, painting, polish, finish, shape, weight, size, and may also differ insignificantly from each other, making them unique & special. Return & Exchange will not be applicable in such scenarios.<br />
                                    2. Wooden products may develop hairline cracks with changes in temperature, climate, and over time. This is a natural process. Return & Exchange will not be applicable in such scenarios.<br />
                                    3. Antiques & Vintage Collections are not machine-made or machine-finished or newly manufactured. There may be multiple imperfections, damage, dents, color blemishes, etc. We retain the original old look the way the product was collected, and we do not repair or modify any imperfections to maintain the authenticity of the time & source. Return & Exchange will not be applicable in such scenarios.<br />
                                    4. Handmade or Handpainted or Handcrafted or Vintage or Antique products will not be exactly similar to each other in terms of color shades, texture, painting, polish, finish, shape, weight, size. The photograph may not be an exact representation of the product you receive, but of course, similar. Any dispute, Return & Exchange will not be applicable in such scenarios.<br />
                                    5. Products bought online cannot be returned or exchanged in store.<br />
                                    6. Products offered in sale cannot be returned or exchanged.<br />
                                    7. Any dispute or concern regarding any product should be brought to the attention of our Customer Care within 3 Business Days from the delivery. After this timeline, Return & Exchange will not be applicable.<br />
                                    8. Return & Exchange policy will be applicable to products returned to us without any damage – in their original condition.<br />
                                    9. Return & Exchange policy will be applicable to returned products that are unused, unwashed, and have all their original packaging and tags intact.<br />
                                    10. In cases where a customer has provided an incorrect or incomplete delivery address and our shipping partner fails to deliver after 3 attempts, or the recipient refuses to accept the delivery, Return & Exchange will not be applicable. The customer will need to pay additional shipping charges to initiate the dispatch process again from our end.<br />
                                    11. For international orders, if a customer refuses to pay the duty as mandated by the respective country of shipment and doesn't collect their order from the shipping agency, Return & Exchange will not be applicable.<br />
                                    The above eligibility for Return & Exchange is applicable for both domestic and international purchases.<br />
                                </p>
                                <div className="contact-info"><h6>PROCESS TO RETURN OR EXCHANGE</h6></div>
                                <p className="font14">
                                    1. Always record the unboxing video and photographs of the products. Check the products carefully. If incorrect, damaged, or genuine quality/manufacturing defects are found, contact Hintage Customer Care within 48 hours of delivery with the unboxing video and photographs of the concerned product.<br />
                                    Customer Care: care@hintage.in<br />
                                    Phone Number with WhatsApp: [Phone Number] (between [Hours of Operation])<br /><br />
                                    Mailing Address: Hintage<br />
                                    [Company Address]

                                    2. The Hintage team will review your concern and respond with a solution within 7 Business Days.<br /><br />
                                    3. In case of a Reverse Pick Up will be arranged by us at our own expense. However, if your Pin Code is not serviceable for Reverse Pick Up, you need to arrange the shipping of the products to our address mentioned above.<br /><br />
                                    4. Once the product is received by us in original condition, we will initiate fresh shipping within 7 Business Days at our own expense. Please note, in case of unavailability of the same product, Yellow Verandah holds sole right to proceed for full Refund. Refund will be processed to your original method of payment.<br /><br /><br />
                                    It will usually take 15 Business Days to return or exchange or refund from the day of reporting the issue.<br /><br />
                                    Disclaimer: All policies are subject to change without prior notice.<br />
                                </p>                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
  );
}

export default Return;
