import React, { useEffect, useState } from "react";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import { useDispatch } from "react-redux";

const $ = require('jquery');

// https://www.npmjs.com/package/react-owl-carousel

function ProductCarousel(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    console.log ("ProductCarousel", props);

    useEffect(() => {
        $('.team-wrap').each( function()    {
            var selector = $(this).find('.team-social > span');
            selector.on('click', function(){
                $(this).parent().toggleClass('active');
            });
        });
    } , []);


  const state= {
    responsive:{
        0: { items: 1 },
        450: { items: 2 },
        600: { items: 4 },
        1000: { items: 5 },
    },
}

  return (
  <section className="section-team-list team-1">
    <div className="container">
        <div className="row">
            <div className="col-lg-12 text-center hintage-align-center">
                <div className="ot-heading is-dots">
                    <span>[ Categories ]</span>
                    <h2 className="main-heading">Explore more</h2>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid">
        <div className="row">
            <OwlCarousel className='owl-theme' loop margin={10} autoplay autoplayHoverPause nav
                // stagePadding={100}
                responsive={state.responsive}
                touchDrag={true}
                lazyLoad={true}
                animateOut={'fadeOut'}
                animateIn={'flipInX'}
                autoplayTimeout={3500}>
                <div className="item">
                    <div className="team-wrap">
                        <div className="team-thumb">
                            <img src="https://products.hintage.in/products/item-8/thumbnail-sm.jpg" alt="Hintage Image" />
                        </div>
                        {/* <div className="team-text-overlay">
                            <div className="team-info dtable">
                                <div className="dcell">
                                    <h4 className="m_name">Christina Torres</h4>
                                    <div className="team-social flex-middle">
                                        <span className="ot-flaticon-add"></span>
                                        <a href="https://twitter.com/"><i className="fab fab fa-twitter"></i></a>
                                        <a href="https://linkedin.com/"><i className="fab fab fa-linkedin-in"></i></a>
                                        <a href="https://instagram.com/"><i className="fab fab fa-instagram"></i></a>
                                    </div>  
                                </div>
                                <div className="m_extra">
                                    <span>[ Company Founder ]</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="item">
                    <div className="team-wrap">
                        <div className="team-thumb">
                            <img src="https://products.hintage.in/products/item-9/thumbnail-sm.jpg" alt="Hintage Image" />
                        </div>
                        {/* <div className="team-text-overlay">
                            <div className="team-info dtable">
                                <div className="dcell">
                                    <h4 className="m_name">Jesica Lina</h4>
                                    <div className="team-social flex-middle">
                                        <span className="ot-flaticon-add"></span>
                                        <a href="https://twitter.com/"><i className="fab fab fa-twitter"></i></a>
                                        <a href="https://linkedin.com/"><i className="fab fab fa-linkedin-in"></i></a>
                                        <a href="https://instagram.com/"><i className="fab fab fa-instagram"></i></a>
                                    </div>  
                                </div>
                                <div className="m_extra">
                                    <span>[ Marketing Manager ]</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="item">
                    <div className="team-wrap">
                        <div className="team-thumb">
                            <img src="https://products.hintage.in/products/item-10/thumbnail-sm.jpg" alt="Hintage Image" />
                        </div>
                        {/* <div className="team-text-overlay">
                            <div className="team-info dtable">
                                <div className="dcell">
                                    <h4 className="m_name">Robert Cooper</h4>
                                    <div className="team-social flex-middle">
                                        <span className="ot-flaticon-add"></span>
                                        <a href="https://twitter.com/"><i className="fab fab fa-twitter"></i></a>
                                        <a href="https://linkedin.com/"><i className="fab fab fa-linkedin-in"></i></a>
                                        <a href="https://instagram.com/"><i className="fab fab fa-instagram"></i></a>
                                    </div>  
                                </div>
                                <div className="m_extra">
                                    <span>[ Finance Manager ]</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="item">
                    <div className="team-wrap">
                        <div className="team-thumb">
                            <img src="https://products.hintage.in/products/item-11/thumbnail-sm.jpg" alt="Hintage Image" />
                        </div>
                        {/* <div className="team-text-overlay">
                            <div className="team-info dtable">
                                <div className="dcell">
                                    <h4 className="m_name">Olivia Peterson</h4>
                                    <div className="team-social flex-middle">
                                        <span className="ot-flaticon-add"></span>
                                        <a href="https://twitter.com/"><i className="fab fab fa-twitter"></i></a>
                                        <a href="https://linkedin.com/"><i className="fab fab fa-linkedin-in"></i></a>
                                        <a href="https://instagram.com/"><i className="fab fab fa-instagram"></i></a>
                                    </div>  
                                </div>
                                <div className="m_extra">
                                    <span>[ CEO of Company ]</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="item">
                    <div className="team-wrap">
                        <div className="team-thumb">
                            <img src="https://products.hintage.in/products/item-12/thumbnail-sm.jpg" alt="Hintage Image" />
                        </div>
                        {/* <div className="team-text-overlay">
                            <div className="team-info dtable">
                                <div className="dcell">
                                    <h4 className="m_name">Amalia Bruno</h4>
                                    <div className="team-social flex-middle">
                                        <span className="ot-flaticon-add"></span>
                                        <a href="https://twitter.com/"><i className="fab fab fa-twitter"></i></a>
                                        <a href="https://linkedin.com/"><i className="fab fab fa-linkedin-in"></i></a>
                                        <a href="https://instagram.com/"><i className="fab fab fa-instagram"></i></a>
                                    </div>  
                                </div>
                                <div className="m_extra">
                                    <span>[ Interior Designer ]</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="item">
                    <div className="team-wrap">
                        <div className="team-thumb">
                            <img src="https://products.hintage.in/products/item-14/thumbnail-sm.jpg" alt="Hintage Image" />
                        </div>
                        {/* <div className="team-text-overlay">
                            <div className="team-info dtable">
                                <div className="dcell">
                                    <h4 className="m_name">Katie Doyle</h4>
                                    <div className="team-social flex-middle">
                                        <span className="ot-flaticon-add"></span>
                                        <a href="https://twitter.com/"><i className="fab fab fa-twitter"></i></a>
                                        <a href="https://linkedin.com/"><i className="fab fab fa-linkedin-in"></i></a>
                                        <a href="https://instagram.com/"><i className="fab fab fa-instagram"></i></a>
                                    </div>  
                                </div>
                                <div className="m_extra">
                                    <span>[ Interior Designer ]</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="item">
                    <div className="team-wrap">
                        <div className="team-thumb">
                            <img src="https://products.hintage.in/products/item-15/thumbnail-sm.jpg" alt="Hintage Image" />
                        </div>
                        {/* <div className="team-text-overlay">
                            <div className="team-info dtable">
                                <div className="dcell">
                                    <h4 className="m_name">Andrew Kinzer</h4>
                                    <div className="team-social flex-middle">
                                        <span className="ot-flaticon-add"></span>
                                        <a href="https://twitter.com/"><i className="fab fab fa-twitter"></i></a>
                                        <a href="https://linkedin.com/"><i className="fab fab fa-linkedin-in"></i></a>
                                        <a href="https://instagram.com/"><i className="fab fab fa-instagram"></i></a>
                                    </div>  
                                </div>
                                <div className="m_extra">
                                    <span>[ CEO of Company ]</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="item">
                    <div className="team-wrap">
                        <div className="team-thumb">
                            <img src="https://products.hintage.in/products/item-23/thumbnail-sm.jpg" alt="Hintage Image" />
                        </div>
                        {/* <div className="team-text-overlay">
                            <div className="team-info dtable">
                                <div className="dcell">
                                    <h4 className="m_name">Anna Richmond</h4>
                                    <div className="team-social flex-middle">
                                        <span className="ot-flaticon-add"></span>
                                        <a href="https://twitter.com/"><i className="fab fab fa-twitter"></i></a>
                                        <a href="https://linkedin.com/"><i className="fab fab fa-linkedin-in"></i></a>
                                        <a href="https://instagram.com/"><i className="fab fab fa-instagram"></i></a>
                                    </div>  
                                </div>
                                <div className="m_extra">
                                    <span>[ Marketing Manager ]</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="item">
                    <div className="team-wrap">
                        <div className="team-thumb">
                            <img src="https://products.hintage.in/products/item-17/thumbnail-sm.jpg" alt="Hintage Image" />
                        </div>
                        {/* <div className="team-text-overlay">
                            <div className="team-info dtable">
                                <div className="dcell">
                                    <h4 className="m_name">Christina Torres</h4>
                                    <div className="team-social flex-middle">
                                        <span className="ot-flaticon-add"></span>
                                        <a href="https://twitter.com/"><i className="fab fab fa-twitter"></i></a>
                                        <a href="https://linkedin.com/"><i className="fab fab fa-linkedin-in"></i></a>
                                        <a href="https://instagram.com/"><i className="fab fab fa-instagram"></i></a>
                                    </div>  
                                </div>
                                <div className="m_extra">
                                    <span>[ Company Founder ]</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="item">
                    <div className="team-wrap">
                        <div className="team-thumb">
                            <img src="https://products.hintage.in/products/item-18/thumbnail-sm.jpg" alt="Hintage Image" />
                        </div>
                        {/* <div className="team-text-overlay">
                            <div className="team-info dtable">
                                <div className="dcell">
                                    <h4 className="m_name">Jesica Lina</h4>
                                    <div className="team-social flex-middle">
                                        <span className="ot-flaticon-add"></span>
                                        <a href="https://twitter.com/"><i className="fab fab fa-twitter"></i></a>
                                        <a href="https://linkedin.com/"><i className="fab fab fa-linkedin-in"></i></a>
                                        <a href="https://instagram.com/"><i className="fab fab fa-instagram"></i></a>
                                    </div>  
                                </div>
                                <div className="m_extra">
                                    <span>[ Marketing Manager ]</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="item">
                    <div className="team-wrap">
                        <div className="team-thumb">
                            <img src="https://products.hintage.in/products/item-19/thumbnail-sm.jpg" alt="Hintage Image" />
                        </div>
                        {/* <div className="team-text-overlay">
                            <div className="team-info dtable">
                                <div className="dcell">
                                    <h4 className="m_name">Robert Cooper</h4>
                                    <div className="team-social flex-middle">
                                        <span className="ot-flaticon-add"></span>
                                        <a href="https://twitter.com/"><i className="fab fab fa-twitter"></i></a>
                                        <a href="https://linkedin.com/"><i className="fab fab fa-linkedin-in"></i></a>
                                        <a href="https://instagram.com/"><i className="fab fab fa-instagram"></i></a>
                                    </div>  
                                </div>
                                <div className="m_extra">
                                    <span>[ Finance Manager ]</span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </OwlCarousel>            
        </div>
    </div>
    </section>);
}

export default ProductCarousel;
