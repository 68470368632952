import React, { useState } from "react";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";  //Requesting and Storing Data

import { Helmet } from "react-helmet-async";
// import ProductCarousel from "./carousel";
import ProductGrid from "./grid";
// import ProductListing from "./listing";

function Products(props) {
    const history = useHistory();
    
    return (
      <div>
            <Helmet>
              <title>Hintage Products - A Heritage from Hind</title>
              <meta
                name="description"
                content="Hintage Contact - A Heritage from Hind"
              />
            </Helmet>
            <div id="content" className="site-content">
                <div className="page-header dtable text-center header-transparent page-header-product">
                    <div className="dcell">
                        <div className="container">
                            <h1 className="page-title">Products</h1>
                            <ul id="breadcrumbs" className="breadcrumbs none-style">
                                <li><a href="index.html">Home</a></li>
                                <li className="active">Products</li>
                            </ul>    
                        </div>
                    </div>
                </div>
            </div>
            {/* <ProductCarousel /> */}
            <ProductGrid categoryKey={props.categoryKey} />
            {/* <ProductListing /> */}
        </div>
  );
}

export default Products;