import React, { useState } from "react";

import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";

function Shipping() {

  return (
      <div>
            <Helmet>
              <title>Shipping Return - A Heritage from Hind</title>
              <meta
                name="description"
                content="Hintage Shipping - A Heritage from Hind"
              />
            </Helmet>
            <div id="content" className="site-content">
                <div className="page-header dtable text-center header-transparent page-header-other">
                    <div className="dcell">
                        <div className="container">
                            <h1 className="page-title">Shipping</h1>
                            <ul id="breadcrumbs" className="breadcrumbs none-style">
                                <li><a href="index.html">Home</a></li>
                                <li className="active">Shipping</li>
                            </ul>    
                        </div>
                    </div>
                </div>
            </div>

            <section className="contact-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 align-self-center text-justify mb-12 mb-lg-0" style={{marginTop: isMobile ? "0px" : "75px"}}>
                            <div>
                                <div className="contact-info"><h3>Shipping Policy</h3></div>
                                <p className="font14">
                                    Hintage endeavors to ensure that your every purchase is seamless and memorable with the highest standard of quality and shopping experience.<br /><br />
                                    We have a strict Quality Checking process embedded before each product is packed and dispatched. We take utmost care in packing to ensure zero damage during transit – domestic or international, and we ship through registered reputable shipping service providers. In rare scenarios, if any product gets damaged during transit, we take full accountability for the damage, allowing you to shop without any concerns about shipping damage.<br />
                                </p>
                                <div className="contact-info"><h5>DOMESTIC SHIPPING</h5></div>
                                <div className="contact-info"><h6>SHIPPING CHARGES</h6></div>
                                <p className="font14">
                                    We offer Free Shipping within India on your minimum order value of Rs 2000 and above. Hence you will not pay any additional shipping charges at the time of checkout. In case your order value is less than Rs 2000, you will see the shipping charges at the time of checkout, in addition to the product prices.<br />
                                    We use surface delivery and not air delivery. Each consignment is insured, hence in rare scenarios, if the consignment is lost or the product gets damaged during shipment, you will get a full refund or exchange.<br />
                                </p>
                                <div className="contact-info"><h6>DOMESTIC SHIPPING TIMELINE</h6></div>
                                <p className="font14">
                                    We usually dispatch your order within 2-3 Business Days from the order placement date (the dispatch can get delayed in case of product non-readiness or any customization or any delay from the shipping company. However, this is a rare scenario).<br /><br />
                                    Once dispatched from our end, the delivery timeline will depend on the shipping company. Usually, it takes 5 – 7 Business Days to deliver within India.<br /><br />
                                    Please note: The delivery timelines above are indicative only. Since we rely on our shipping companies for delivery, this timeline can vary due to factors that are outside our control.
                                </p>
                                <div className="contact-info"><h5>INTERNATIONAL SHIPPING</h5></div>
                                <div className="contact-info"><h6>SHIPPING CHARGES</h6></div>
                                <p className="font14">
                                    Our international shipping is facilitated by reputable shipping companies. For International Shipping, the charges are calculated based on the volumetric weight, actual weight, and destination. Contact us for an approximate estimation of the shipping charge before you place the order. Call or WhatsApp us at [Phone Number] or write to us at care@hintage.in.<br />
                                    Please note, our website does not recognize international shipping while you place an order with an international delivery address. Hence, it still shows Free Shipping on & above order value of Rs 2000. International shipping is NOT FREE.<br />
                                    Once you pay the shipping charges to us, we will dispatch your order.<br />
                                </p>
                                <div className="contact-info"><h6>DUTIES & TAXES FOR INTERNATIONAL ORDERS</h6></div>
                                <p className="font14">
                                    Duties & Taxes for international orders are not included in your order and are over and above any shipping charges paid to us. Most countries charge duties on imported items which are levied at the time of port entry, and they vary based on the destination country and the products imported. You will need to pay the applicable duties and taxes directly to the shipping companies at the time of your order delivery after you receive an invoice from the shipping agent.<br />
                                </p>
                                <div className="contact-info"><h6>INTERNATIONAL SHIPPING TIMELINE</h6></div>
                                <p className="font14">
                                    We usually dispatch your order within 2-3 Business Days from the order placement date (the dispatch can get delayed in case of product non-readiness or any customization or any delay from the shipping company. However, this is a rare scenario).<br />
                                    Once dispatched from our end, the delivery timeline will depend on the shipping company. Usually, it takes 5 – 7 Business Days to deliver anywhere in the world.<br />
                                    Please note: The delivery timelines above are indicative only. Since we rely on reputable shipping companies for delivery, this timeline can vary due to factors that are outside our control.<br />
                                </p>
                                <div className="contact-info"><h6>NON-AVAILABILITY ON DELIVERY</h6></div>
                                <p className="font14">
                                    Our shipping companies will attempt to deliver the order 3 times before they return it to us.<br />
                                    Please provide the complete & accurate shipping address including zip code and a mobile number. This will help us in delivering your order faster.<br />
                                </p>
                                <div className="contact-info"><h6>CASH ON DELIVERY</h6></div>
                                <p className="font14">
                                    We do not have Cash On Delivery option available.<br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
  );
}

export default Shipping;
