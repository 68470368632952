import AuthAction from "./AuthAction";

const initState = {
  isLogin: false,
  userDetails: {
    name: "",
  },
};
export default class AuthReducer {
  static initialState = initState;

  static reducer(state = AuthReducer.initialState, action) {
    if (action.error) {
      return state;
    }
    switch (action.type) {
      case AuthAction.SAVE_USER_DATA:
        return {
          ...state,
          userDetails: {
            ...state.userDetails,
          },
        };
      case AuthAction.LOGIN:
        let data = action.payload;
        let fullName = `${data.first_name} ${data.last_name}`;
        return {
          ...state,
          isLogin: true,
          userDetails: {
            ...state.userDetails,
            name: fullName,
          },
        };
      case AuthAction.LOGOUT:
        return {
          ...state,
          isLogin: false,
        };

      default:
        return state;
    }
  }
}
