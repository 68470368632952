import React, { useState } from "react";

function BannerSlider() {
  return (<div id="rev_slider_one_wrapper" className="rev_slider_wrapper fullscreen-container" data-alias="mask-showcase" data-source="gallery">
        <div id="rev_slider_one" className="rev_slider fullscreenbanner" style={{display:"none"}} data-version="5.4.1">
            <ul>
                <li data-index="rs-70" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off"  data-easein="default" data-easeout="default" data-masterspeed="300"  data-thumb="images/slider/slider1-home1-1.jpg"  data-rotate="0"  data-saveperformance="off"  data-title="" data-param1="1" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                    <img src="images/hintage/slider/slider1-home.jpg" data-bgcolor='rgba(255,255,255,0)' alt="" 
                    data-bgposition="center center" data-bgfit="cover" className="rev-slidebg" data-bgrepeat="no-repeat" 
                    data-bgparallax="off" data-no-retina  />
                    <div className="tp-caption tp-resizeme tp-caption-big" 
                        id="slide-70-layer-1" 
                        data-x="['center','center','center','center']" data-hoffset="['56','46','34','0']" 
                        data-y="['center','center','center','center']" data-voffset="['-64','-72','-65','-50']" 
                        data-fontsize="['206',150','0','0']"
                        data-lineheight="['206','170','127','80']"
                        data-letterspacing="['104','85','63','39']"
                        data-fontweight="['900','900','900','900']"
                        data-color="['transparent','transparent','transparent','transparent']"
                        data-whitespace="nowrap"
                        data-type="text" 
                        data-responsive_offset="on" 
                        data-frames='[{"delay":500,"split":"chars","splitdelay":0.1,"speed":500,"frame":"0","from":"x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"power4.inOut"},{"delay":"wait","speed":1000,"frame":"999","to":"x:50px;z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;","ease":"power3.inOut"}]'
                        data-textalign="['center','center','center','center']"
                        >Shop
                    </div>
                    <div className="tp-caption tp-resizeme tp-caption-main" 
                        id="slide-70-layer-2" 
                        data-x="['center','center','center','center']" data-hoffset="['2','0','0','0']" 
                        data-y="['center','center','center','center']" data-voffset="['-56','-63','-60','-65']"
                        data-fontsize="['93','72','55','40']"
                        data-lineheight="['83','70','51','55']"
                        data-color="['#fff','#fff','#fff','#fff']"
                        data-fontweight="['200','200','200','200']"
                        data-whitespace="nowrap"
                        data-type="text" 
                        data-responsive_offset="on" 
                        data-frames='[{"delay":900,"speed":1000,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"power3.inOut"},{"delay":"wait","speed":1000,"frame":"999","to":"x:50px;opacity:0;","ease":"power3.inOut"}]'
                        data-textalign="['left','left','left','left']"
                        >Unearth Indian Treasures
                    </div>
                    <div className="tp-caption tp-resizeme tp-desc" 
                        id="slide-70-layer-3" 
                        data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                        data-y="['center','center','center','center']" data-voffset="['54','43','31','15']" 
                        data-fontsize="['19','18','17','16']"
                        data-lineheight="['33','27','28','24']"
                        data-width="['818','630','500','417']"
                        data-weight="['500','500','500','500']"
                        data-color="['#fff','#fff','#fff','#fff']"
                        data-whitespace="normal"
                        data-type="text" 
                        data-responsive_offset="on" 
                        data-frames='[{"delay":1200,"speed":1000,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"power3.inOut"},{"delay":"wait","speed":1000,"frame":"999","to":"x:50px;opacity:0;","ease":"power3.inOut"}]'
                        data-textalign="['center','center','center','center']"
                        >Shop Now and Unearth India's Artistic Treasures!
                        </div> 
                    <div className="tp-caption rev-btn" 
                        id="slide-70-layer-4" 
                        data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"  
                        data-y="['center','center','center','center']" data-voffset="['170','140','119','110']"
                        data-fontsize="['13','18','17','0']"
                        data-lineheight="['25','18','16','16']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"   
                        data-responsive_offset="on" 
                        data-frames='[{"delay":1500,"speed":1000,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"power3.inOut"},{"delay":"wait","speed":1000,"frame":"999","to":"x:50px;opacity:0;","ease":"power3.inOut"}]'
                        data-textalign="['center','center','center','center']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        >
                            {/* <a href="portfolio-masonry.html" className="octf-btn octf-btn-primary btn-slider btn-large">View Products</a> */}
                    </div>  
                </li>  
                <li data-index="rs-71" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off"  data-easein="default" data-easeout="default" data-masterspeed="300"  data-thumb="images/slider/slider2-home1-1.jpg"  data-rotate="0"  data-saveperformance="off"  data-title="" data-param1="1" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                    <img src="images/hintage/slider/slider2-home.jpg" data-bgcolor='#ffffff' alt="" 
                    data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" 
                    className="rev-slidebg" data-bgparallax="off" data-no-retina />
                    <div className="tp-caption tp-resizeme tp-caption-big" 
                        id="slide-71-layer-1" 
                        data-x="['center','center','center','center']" data-hoffset="['56','46','34','0']" 
                        data-y="['center','center','center','center']" data-voffset="['-64','-72','-65','-50']" 
                        data-fontsize="['206',150','0','0']"
                        data-lineheight="['206','170','127','80']"
                        data-letterspacing="['104','85','63','39']"
                        data-fontweight="['900','900','900','900']"
                        data-color="['transparent','transparent','transparent','transparent']"
                        data-whitespace="nowrap"
                        data-type="text" 
                        data-responsive_offset="on" 
                        data-frames='[{"delay":500,"split":"chars","splitdelay":0.1,"speed":500,"frame":"0","from":"x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"power4.inOut"},{"delay":"wait","speed":1000,"frame":"999","to":"x:50px;z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;","ease":"power3.inOut"}]'
                        data-textalign="['center','center','center','center']"
                        >Art
                    </div>
                    <div className="tp-caption tp-resizeme tp-caption-main" 
                        id="slide-71-layer-2" 
                        data-x="['center','center','center','center']" data-hoffset="['2','0','0','0']" 
                        data-y="['center','center','center','center']" data-voffset="['-56','-63','-60','-65']"
                        data-fontsize="['93','72','55','40']"
                        data-lineheight="['83','70','51','55']"
                        data-color="['#fff','#fff','#fff','#fff']"
                        data-fontweight="['200','200','200','200']"
                        data-whitespace="nowrap"
                        data-type="text" 
                        data-responsive_offset="on" 
                        data-frames='[{"delay":900,"speed":1000,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"power3.inOut"},{"delay":"wait","speed":1000,"frame":"999","to":"x:50px;opacity:0;","ease":"power3.inOut"}]'
                        data-textalign="['left','left','left','left']"
                        >Indian Art Decor
                    </div>
                    <div className="tp-caption tp-resizeme tp-desc" 
                        id="slide-71-layer-3" 
                        data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                        data-y="['center','center','center','center']" data-voffset="['54','43','31','15']" 
                        data-fontsize="['19','18','17','16']"
                        data-lineheight="['33','27','28','24']"
                        data-width="['818','630','500','417']"
                        data-weight="['500','500','500','500']"
                        data-color="['#fff','#fff','#fff','#fff']"
                        data-whitespace="normal"
                        data-type="text" 
                        data-responsive_offset="on" 
                        data-frames='[{"delay":1200,"speed":1000,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"power3.inOut"},{"delay":"wait","speed":1000,"frame":"999","to":"x:50px;opacity:0;","ease":"power3.inOut"}]'
                        data-textalign="['center','center','center','center']"
                        >Find Your Indian Art Inspired Home Decor Now!
                        </div> 
                    <div className="tp-caption rev-btn" 
                        id="slide-71-layer-4" 
                        data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"  
                        data-y="['center','center','center','center']" data-voffset="['170','140','119','110']"
                        data-fontsize="['13','18','17','0']"
                        data-lineheight="['25','18','16','16']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"   
                        data-responsive_offset="on" 
                        data-frames='[{"delay":1500,"speed":1000,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"power3.inOut"},{"delay":"wait","speed":1000,"frame":"999","to":"x:50px;opacity:0;","ease":"power3.inOut"}]'
                        data-textalign="['center','center','center','center']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        >
                            {/* <a href="portfolio-masonry.html" className="octf-btn octf-btn-primary btn-slider btn-large">View Products</a> */}
                    </div>  
                </li>  
                <li data-index="rs-72" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off"  data-easein="default" data-easeout="default" data-masterspeed="300"  data-thumb="images/slider/slider3-home1-1.jpg"  data-rotate="0"  data-saveperformance="off"  data-title="" data-param1="1" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                    <img src="images/hintage/slider/slider3-home.jpg" data-bgcolor='#ffffff' alt="" 
                    data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" 
                    data-bgparallax="off" data-no-retina />
                    <div className="tp-caption tp-resizeme tp-caption-big" 
                        id="slide-72-layer-1" 
                        data-x="['center','center','center','center']" data-hoffset="['56','46','34','0']" 
                        data-y="['center','center','center','center']" data-voffset="['-64','-72','-65','-50']" 
                        data-fontsize="['206',150','0','0']"
                        data-lineheight="['206','170','127','80']"
                        data-letterspacing="['104','85','63','39']"
                        data-fontweight="['900','900','900','900']"
                        data-color="['transparent','transparent','transparent','transparent']"
                        data-whitespace="nowrap"
                        data-type="text" 
                        data-responsive_offset="on" 
                        data-frames='[{"delay":500,"split":"chars","splitdelay":0.1,"speed":500,"frame":"0","from":"x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"power4.inOut"},{"delay":"wait","speed":1000,"frame":"999","to":"x:50px;z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;","ease":"power3.inOut"}]'
                        data-textalign="['center','center','center','center']"
                        >Explore
                    </div>
                    <div className="tp-caption tp-resizeme tp-caption-main" 
                        id="slide-72-layer-2" 
                        data-x="['center','center','center','center']" data-hoffset="['2','0','0','0']" 
                        data-y="['center','center','center','center']" data-voffset="['-56','-63','-60','-65']"
                        data-fontsize="['93','72','55','40']"
                        data-lineheight="['83','70','51','55']"
                        data-color="['#fff','#fff','#fff','#fff']"
                        data-fontweight="['200','200','200','200']"
                        data-whitespace="nowrap"
                        data-type="text" 
                        data-responsive_offset="on" 
                        data-frames='[{"delay":900,"speed":1000,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"power3.inOut"},{"delay":"wait","speed":1000,"frame":"999","to":"x:50px;opacity:0;","ease":"power3.inOut"}]'
                        data-textalign="['left','left','left','left']"
                        >Explore Heritage
                    </div>
                    <div className="tp-caption tp-resizeme tp-desc" 
                        id="slide-72-layer-3" 
                        data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                        data-y="['center','center','center','center']" data-voffset="['54','43','31','15']" 
                        data-fontsize="['19','18','17','16']"
                        data-lineheight="['33','27','28','24']"
                        data-width="['818','630','500','417']"
                        data-weight="['500','500','500','500']"
                        data-color="['#fff','#fff','#fff','#fff']"
                        data-whitespace="normal"
                        data-type="text" 
                        data-responsive_offset="on" 
                        data-frames='[{"delay":1200,"speed":1000,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"power3.inOut"},{"delay":"wait","speed":1000,"frame":"999","to":"x:50px;opacity:0;","ease":"power3.inOut"}]'
                        data-textalign="['center','center','center','center']"
                        >Shop Vintage India Now!
                    </div> 
                    <div className="tp-caption rev-btn" 
                        id="slide-72-layer-4" 
                        data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"  
                        data-y="['center','center','center','center']" data-voffset="['170','140','119','110']"
                        data-fontsize="['13','18','17','0']"
                        data-lineheight="['25','18','16','16']"
                        data-width="none"
                        data-height="none"
                        data-whitespace="nowrap"   
                        data-responsive_offset="on" 
                        data-frames='[{"delay":1500,"speed":1000,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"power3.inOut"},{"delay":"wait","speed":1000,"frame":"999","to":"x:50px;opacity:0;","ease":"power3.inOut"}]'
                        data-textalign="['center','center','center','center']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        >
                            {/* <a href="portfolio-masonry.html" className="octf-btn octf-btn-primary btn-slider btn-large">View Products</a> */}
                    </div>  
                </li>  
            </ul>
            <div className="tp-bannertimer"></div>
        </div>
    </div>
   );
}

export default BannerSlider;
