import React, { useState, useEffect } from "react";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import { useDispatch } from "react-redux";  //Requesting and Storing Data
import { useSelector } from "react-redux";  //Reading Data
import ProductsAction from "store/Products/ProductsAction";

function ProductGrid(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [imageIndex, setImageIndex] = useState([]);

    const productsStore = useSelector((state) => state.products);
    console.log("productsStore", productsStore);
    const productList = productsStore.productDetailsForCategory.data;

    console.log("props.categoryKey", props.categoryKey);

    useEffect(() => {
      getAllProducts(props.categoryKey || null);
    }, []);
    
    const getAllProducts =async (category_key) => {
      setIsLoading(true);        
      if (category_key === '*') category_key = null;
      await dispatch(await ProductsAction.productListForCategory(category_key, 1, 10, 'ASC'));
      setIsLoading(false);
      // let response = await HttpUtility.get(`${ProductList}?page_no=1&page_size=10&order_by_type=ASC`, {});
      // if (response.status === "success") setProductList(response.data);
    }
    
    const productClick = (product) => {
        console.log("product--------", product);
        setImageIndex(0);
        setSelectedProduct(product);
    }
    
    const productImages = [];
    if (selectedProduct !== null) {
        const variantList = selectedProduct.variantList || [];
        variantList.map((variant, key) => {
            const variantImages = variant.variantImages || [];
            variantImages.map(variantImg => productImages.push(variantImg.fullPath));
        });
    }
         

    return (<section className="p-0">
    <div className="projects-grid pf_5_cols style-2 p-info-s2 img-scale w-auto no-gaps mx-0">
        <div className="grid-sizer"></div>
        {productList.length === 0 ? 
            <div className="text-center" style={{fontFamily:"Titillium Web, sans-serif", padding: "50px"}}><br /><br />
                <h4>No product listed in this category...</h4>
            </div> 
            :
            productList.map((item, key) => {
                let sell_price = 0.0;
                const variantList = item.variantList || [];
                variantList.map ((varItem, key) => {
                    sell_price = varItem.sell_price;
                });

                return <div className={`project-item ${item.category_key} ${key === 0 ? 'thumb2x' : ''}`}>
                    <div className="projects-box">
                        <div className="projects-thumbnail">
                            <a href="javascript:void(0)" onClick={() => productClick(item)} >
                                <img src={item.thumbnail_bg_img_full_path} alt="" />
                            </a>
                            <div className="overlay">
                                <h5>{item.product_title}</h5>
                                <i className="ot-flaticon-add"></i>
                            </div>
                        </div>
                        <div className="portfolio-info">
                            <div className="portfolio-info-inner">
                                <h5>
                                    <a className="title-link" href="javascript:void(0)" onClick={() => productClick(item)} >
                                        {item.product_title}
                                    </a>
                                </h5>
                                <p className="portfolio-cates">
                                    <a href="javascript:void(0)" onClick={() => productClick(item)}>Price Rs. {sell_price}</a>
                                </p>
                            </div>
                            <a className="overlay" href="javascript:void(0)" onClick={() => productClick(item)}></a>
                        </div>
                    </div>
                </div>
            })
        }
         {selectedProduct !== null && productImages.length > 0 &&
                <Lightbox
                    mainSrc={productImages[imageIndex]}
                    nextSrc={productImages[(imageIndex + 1) % productImages.length]}
                    prevSrc={productImages[(imageIndex + productImages.length - 1) % productImages.length]}
                    onCloseRequest={() => setSelectedProduct(null)}
                    onMovePrevRequest={() => 
                        setImageIndex((imageIndex + productImages.length - 1) % productImages.length) 
                    }
                    onMoveNextRequest={() =>
                        setImageIndex((imageIndex + 1) % productImages.length)
                    }
                />
            }
        </div>
    </section>);
}

export default ProductGrid;
