import React, { useEffect, useState } from "react";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";

const $ = require('jquery');

function Footer() {
    const history = useHistory();
    const dispatch = useDispatch();
  
    /* --------------------------------------------------
    * back to top
    * --------------------------------------------------*/
    useEffect(() => {    
        if ($('#back-to-top').length) {
            var scrollTrigger = 500, // px
                backToTop = function () {
                    var scrollTop = $(window).scrollTop();
                    if (scrollTop > scrollTrigger) {
                        $('#back-to-top').addClass('show');
                    } else {
                        $('#back-to-top').removeClass('show');
                    }
                };
            backToTop();
            $(window).on('scroll', function () {
                backToTop();
            });
            $('#back-to-top').on('click', function (e) {
                e.preventDefault();
                $('html,body').animate({
                    scrollTop: 0
                }, 700);
            });	
        }
    }, []);


  return (<div>
    <footer id="site-footer" className="site-footer">
        <div className="container">
            <div className="row">
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-4 mb-xl-0">
                    <div className="widget-footer">
                        <img src="images/logo.png" className="footer-logo" alt="" />
                        <p>Heritage from Hind !</p>
                        <div className="footer-social list-social">
                            <ul>
                                <li><a href="https://www.facebook.com/hintage" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://www.twitter.com/hintage_1" target="_blank"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/in/hintage" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                                <li><a href="https://www.instagram.com/HintageIndia" target="_blank"><i className="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-4 mb-xl-0">
                    <div className="widget-footer">
                        <div className="widget-footer widget-contact">
                            <h6>Hintage Customer Support</h6>
                            <ul>
                                {/* <li><a href="#">Customer Care</a></li> */}
                                <li><a href="/#/contact">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-4 mb-md-0">
                    <div className="widget-footer widget-contact">
                        <h6>About Hintage</h6>
                        <ul>
                            <li><Link to="/terms-conditions">Terms of Use</Link></li>
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            <li><Link to="/about-us">Our Story</Link></li>
                            <li><Link to="/refund-policy">Refund Policy</Link></li>
                            <li><Link to="/return-policy">Return Policy</Link></li>
                            <li><Link to="/shipping-policy">Shipping Policy</Link></li>
                        </ul>
                    </div>
                </div>
                 <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                    <div className="widget-footer footer-widget-subcribe">
                        <h6>Subscribe</h6>
                        <form className="mc4wp-form" method="post">
                            <div className="mc4wp-form-fields">
                                <div className="subscribe-inner-form">
                                    <input type="email" name="EMAIL" placeholder="YOUR EMAIL" required="" />
                                    <button type="submit" className="subscribe-btn-icon"><i className="ot-flaticon-send"></i></button>
                                </div>
                            </div>
                        </form>
                        <p>Follow our newsletter to stay updated about our products.</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <div className="footer-bottom">
        <div className="container">
            <div className="row">
                <div className="col-lg-7 col-md-12 mb-4 mb-lg-0">
                    <p>Copyright © 2023 by <a className="text-light" href="#">Hintage Team</a>. All Rights Reserved.</p>
                </div>
                {/* <div className="col-lg-5 col-md-12 align-self-center">
                    <ul className="icon-list-items inline-items justify-content-lg-end">
                        <li className="icon-list-item inline-item">
                            <a href="#"><span className="icon-list-text">Terms of use</span></a>
                        </li>
                        <li className="icon-list-item inline-item">
                            <a href="#"><span className="icon-list-text">Privacy Environmental Policy</span></a>
                        </li>
                    </ul>
                </div> */}
            </div>
        </div>
    </div>
    <a id="back-to-top" href="#" className="show"><i className="ot-flaticon-left-arrow"></i></a>
 </div>);
}

export default Footer;
