import React, { useState } from "react";

import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";

function About() {

  return (
      <div>
            <Helmet>
              <title>About Hintage - A Heritage from Hind</title>
              <meta
                name="description"
                content="About Hintage - A Heritage from Hind"
              />
            </Helmet>
            <div id="content" className="site-content">
                <div className="page-header dtable text-center header-transparent page-header-other">
                    <div className="dcell">
                        <div className="container">
                            <h1 className="page-title">About Us</h1>
                            <ul id="breadcrumbs" className="breadcrumbs none-style">
                                <li><a href="index.html">Home</a></li>
                                <li className="active">About Us</li>
                            </ul>    
                        </div>
                    </div>
                </div>
            </div>

            <section className="contact-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 align-self-center text-justify mb-12 mb-lg-0" style={{marginTop: isMobile ? "0px" : "75px"}}>
                            <div>
                                <div className="contact-info"><h3>About Us - Hintage: Embracing Heritage with a Modern Touch</h3></div>
                                <p className="font14">
                                    Welcome to Hintage, an innovative brand driven by a passion for heritage, where we seamlessly blend the charm of yesteryear with contemporary design sensibilities. We offer a diverse range of vintage products for both home decor and commercial establishments, carefully curated for those who appreciate ancient art and culture.
                                </p>
                                <div className="contact-info"><h6>Our Unique Offerings</h6></div>
                                <p className="font14">
                                    At Hintage, our extensive collection includes vintage home and commercial furniture, hand-painted pieces, and brass, bronze, and copper artifacts and utensils. Each product is meticulously handcrafted by skilled artisans, boasting an exclusive finish and exquisite artwork that sets Hintage apart in the market.<br />                                
                                </p>
                                <div className="contact-info"><h6>Our Vision</h6></div>
                                <p className="font14">
                                    Our deep-seated vision revolves around reviving and disseminating ancient art, culture, and architecture. We strive to preserve age-old traditions while bringing them to the forefront of modern design, striking a balance between past and present. By collaborating with talented artisans, we showcase their work and foster a global appreciation for intricate art forms.<br />
                                </p>
                                <div className="contact-info"><h6>Our Commitment</h6></div>
                                <p className="font14">
                                    Hintage's unwavering dedication to quality and authenticity is rooted in our commitment to cultural preservation, making us a leading force in the world of vintage design.<br />
                                </p>
                                <div className="contact-info"><h6>Empowering Artisans</h6></div>
                                <p className="font14">
                                    We believe in empowering artisans and promoting their art worldwide through innovative use of technology. By leveraging digital platforms and technological advancements, we ensure that our products reach a global audience, providing artisans with the recognition and financial support they deserve.
                                </p>
                                <div className="contact-info"><h6>Our Impact</h6></div>
                                <p className="font14">
                                    Our unique business model enables us to make a meaningful impact on the lives of artisans while offering customers an unparalleled shopping experience. With Hintage, you can be assured that you're not only investing in beautiful vintage pieces, but also supporting a brand that stands for cultural preservation, empowerment, and authenticity.<br /><br />
                                    
                                    Join us in celebrating the rich history and culture embedded in each handcrafted product, and become a part of the Hintage family today<br />
                                </p>                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
  );
}

export default About;
