import React, { useState } from "react";

// react-router-dom components
import { Link, useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";

function RightPanel() {
    const history = useHistory();
    const dispatch = useDispatch();

  return (<div id="side-panel" className="side-panel">
        <a href="#" className="side-panel-close"><i className="ot-flaticon-close-1"></i></a>
        <div className="side-panel-block">
            <div className="side-panel-wrap">
                <div className="the-logo">
                    <a href="index-html.html">
                        <img src="images/logo.png" alt="Hintage" style={{width: "40%"}}/>
                    </a>                    
                </div>
                <div className="ot-heading">
                    <h2 className="main-heading">Our Products</h2>
                </div>
                <div className="image-gallery">
                    <div id="gallery-1" className="gallery galleryid-102 gallery-columns-3 gallery-size-thumbnail">
                        <figure className="gallery-item">
                            <div className="gallery-icon landscape">
                                <a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="8701e24" data-elementor-lightbox-title="p4-gallery1" href="images/gallery1.jpg">
                                    <img src="images/gallery-small-1.jpg" className="" alt="" />
                                </a>
                            </div>
                        </figure>
                        <figure className="gallery-item">
                            <div className="gallery-icon landscape">
                                <a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="8701e24" data-elementor-lightbox-title="p4-gallery1" href="images/gallery2.jpg">
                                    <img src="images/gallery-small-2.jpg" className="" alt="" />
                                </a>
                            </div>
                        </figure>
                        <figure className="gallery-item">
                            <div className="gallery-icon landscape">
                                <a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="8701e24" data-elementor-lightbox-title="p4-gallery1" href="images/gallery3.jpg">
                                    <img src="images/gallery-small-3.jpg" className="" alt="" />
                                </a>
                            </div>
                        </figure>
                        <figure className="gallery-item">
                            <div className="gallery-icon landscape">
                                <a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="8701e24" data-elementor-lightbox-title="p4-gallery1" href="images/gallery4.jpg">
                                    <img src="images/gallery-small-4.jpg" className="" alt="" />
                                </a>
                            </div>
                        </figure>
                        <figure className="gallery-item">
                            <div className="gallery-icon landscape">
                                <a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="8701e24" data-elementor-lightbox-title="p4-gallery1" href="images/gallery5.jpg">
                                    <img src="images/gallery-small-5.jpg" className="" alt="" />
                                </a>
                            </div>
                        </figure>
                        <figure className="gallery-item">
                            <div className="gallery-icon landscape">
                                <a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="8701e24" data-elementor-lightbox-title="p4-gallery1" href="images/gallery6.jpg">
                                    <img src="images/gallery-small-6.jpg" className="" alt="" />
                                </a>
                            </div>
                        </figure>
                    </div>
                </div>
                <div className="ot-heading ">
                    <h2 className="main-heading">Contact Info</h2>
                </div>
                <div className="side-panel-cinfo">
                    <ul className="panel-cinfo">
                        <li className="panel-list-item">
                            <span className="panel-list-icon"><i className="ot-flaticon-place"></i></span>
                            <span className="panel-list-text">Mulund-West, India</span>
                        </li>
                        <li className="panel-list-item">
                            <span className="panel-list-icon"><i className="ot-flaticon-mail"></i></span>
                            <span className="panel-list-text">care@hintage.in</span>
                        </li>
                        <li className="panel-list-item">
                            <span className="panel-list-icon"><i className="ot-flaticon-phone-call"></i></span>
                            <span className="panel-list-text">+91 98929 83643</span>
                        </li>
                    </ul>
                </div>
                <div className="side-panel-social">
                    <ul>
                        <li><a href="https://www.facebook.com/hintage" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                        <li><a href="https://www.twitter.com/hintage_1" target="_blank"><i className="fab fa-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/in/hintage" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                        <li><a href="https://www.instagram.com/HintageIndia" target="_blank"><i className="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>);
}

export default RightPanel;
