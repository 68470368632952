import CommonAction from "store/Common/CommonAction";
import ActionUtility from "utilities/ActionUtility";
import ProductsEffect from "./ProductsEffect";
import Headers from "../utils/http/Headers";

export default class ProductsAction {
  static SAVE_PRODUCT_DATA = "ProductsAction.SAVE_PRODUCT_DATA";
  static SAVE_PRODUCT_DATA_FOR_CATEGORY = "ProductsAction.SAVE_PRODUCT_DATA_FOR_CATEGORY";
  static SAVE_PRODUCT_CATEGORY_DATA = "ProductsAction.SAVE_PRODUCT_CATEGORY_DATA";
    
  static productList = (category_key, pageNo, pageSize, orderBy) => {
    return async (dispatch, getState) => {     
      const result = await ProductsEffect.productList(category_key, pageNo, pageSize, orderBy);
      if (result.status == "success") {
        dispatch(ActionUtility.createAction(ProductsAction.SAVE_PRODUCT_DATA, result));
      }
    };
  }

  static productListForCategory = (category_key, pageNo, pageSize, orderBy) => {
    return async (dispatch, getState) => {     
      const result = await ProductsEffect.productList(category_key, pageNo, pageSize, orderBy);
      if (result.status == "success") {
        dispatch(ActionUtility.createAction(ProductsAction.SAVE_PRODUCT_DATA_FOR_CATEGORY, result));
      }
    };
  }

  static productCategoryList = (pageNo, pageSize, orderBy) => {
    return async (dispatch, getState) => {     
      const result = await ProductsEffect.productCategoryList(pageNo, pageSize, orderBy);
      if (result.status == "success") {
        dispatch(ActionUtility.createAction(ProductsAction.SAVE_PRODUCT_CATEGORY_DATA, result));
      }
    };
  };
}
