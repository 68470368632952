import React from "react";
import Home from "components/page/home";
import Product from "components/page/product";
import OfferedServices from "components/page/services";
import ncNanoId from "utilities/ncNanoId";
import ContactUs from "components/page/others/ContactUs";
import PrivacyPolicy from "components/page/others/privacy-policy";
import TermsConditions from "components/page/others/terms-conditions";
import About from "components/page/others/about";
import Refund from "components/page/others/refund";
import Return from "components/page/others/return";
import Shipping from "components/page/others/shipping";

export const PRODUCTS_MENU = [ 
  {
    id: ncNanoId(),
    href: "/",
    name: "Decoration",
    children: [
      { id: ncNanoId(), href: "/products-frames", name: "Frame & Pictures", component: <Product categoryKey={"f8678c11-f03b-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-baskets", name: "Boxes & baskets", component: <Product categoryKey={"7be0c2ae-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-mirrors", name: "Mirror", component: <Product categoryKey={"827a376b-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-candle", name: "Candle Holder", component: <Product categoryKey={"85480751-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-vases", name: "Vases & bowls", component: <Product categoryKey={"885f215f-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-clocks", name: "Clocks", component: <Product categoryKey={"8b035334-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-lamps", name: "Lamps", component: <Product categoryKey={"8ecd7726-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-outdoor", name: "Outdoor", component: <Product categoryKey={"91ed10fd-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-decorative", name: "Decorative", component: <Product categoryKey={"95cf11f7-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-art", name: "Art", component: <Product categoryKey={"98eccd96-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-metalware", name: "Metalware", component: <Product categoryKey={"9eca4e1f-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-glass", name: "Glass", component: <Product categoryKey={"a1a050bc-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-decoration-others", name: "Others", component: <Product categoryKey={null} /> },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Furniture",
    children: [
      { id: ncNanoId(), href: "/products-tables", name: "Tables", component: <Product categoryKey={"a7cfe221-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-chairs", name: "Chairs", isNew: true, component: <Product categoryKey={"aa6eb069-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-wall-storage", name: "Wall Storage", component: <Product categoryKey={"af340800-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-knob", name: "Knob & Handles", component: <Product categoryKey={"b3f56330-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-lighting", name: "Lighting", component: <Product categoryKey={"b6eed05a-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-outdoor-furniture", name: "Outdoor Furniture", component: <Product categoryKey={"b9dddde9-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-indoor-furniture", name: "Indoor Furniture", component: <Product categoryKey={"bd7b7c95-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-furniture-others", name: "Others", component: <Product categoryKey={null} /> },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Kitchenware",
    children: [
      { id: ncNanoId(), href: "/products-storage", name: "Storage", component: <Product categoryKey={"c383b09b-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-dinner", name: "Dinnerware", component: <Product categoryKey={"c6b100aa-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-serverware", name: "Serveware", component: <Product categoryKey={"c9f844fa-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-cutlery", name: "Cutlery", component: <Product categoryKey={"ccde2d6d-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-tableware", name: "Tableware", component: <Product categoryKey={"cf99f094-f03c-11ed-8379-0a16e463f21e"} /> },
      { id: ncNanoId(), href: "/products-kitchen-others", name: "Others", component: <Product categoryKey={null} /> },
    ],
  },
];

// export const SERVICE_MENU = [
//     { id: ncNanoId(), href: "/services-desing-planning", name: "Design & Planning", component: <OfferedServices serviceId="1" /> },
//     { id: ncNanoId(), href: "/services-exterior-design", name: "Exterior Design", component: <OfferedServices serviceId="2" /> },
//     { id: ncNanoId(), href: "/services-custom-solutions", name: "Custom Solutions", component: <OfferedServices serviceId="3" /> },
//     { id: ncNanoId(), href: "/services-furniture-decor", name: "Furniture & Decor", component: <OfferedServices serviceId="4" /> },
//     { id: ncNanoId(), href: "/services-concept", name: "Creating Concept", component: <OfferedServices serviceId="5" /> },
//   ];

export const NAVIGATION_MENU = [
  // { id: ncNanoId(), href: "/products-all", name: "Products", children: PRODUCTS_MENU, component: <Product /> },
  // { id: ncNanoId(), href: "/services", name: "Services", children: SERVICE_MENU, component: <OfferedServices serviceId="1" /> },
  { id: ncNanoId(), href: "/contact", name: "Contact us", component: <ContactUs />  },
];

export const FOOTER_ROUTES = [
  { id: ncNanoId(), href: "/terms-conditions", name: "Terms of Use", component: <TermsConditions /> },
  { id: ncNanoId(), href: "/privacy-policy", name: "Privacy Policy", component: <PrivacyPolicy /> },
  { id: ncNanoId(), href: "/about-us", name: "Our Story", component: <About /> },
  { id: ncNanoId(), href: "/refund-policy", name: "Refund Policy", component: <Refund /> },
  { id: ncNanoId(), href: "/return-policy", name: "Return Policy", component: <Return /> },
  { id: ncNanoId(), href: "/shipping-policy", name: "Shipping Policy", component: <Shipping /> },
];
